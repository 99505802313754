import * as React from "react";
import { graphql, Link } from "gatsby";
import anyspherelogo from "../images/anysphere-logo.svg";
import NavBar from "../components/navbar";
import { Helmet } from "react-helmet";
import Footer from "../components/footer";

const BlogPage = ({ data }) => {
  return (
    <main
      style={{
        fontFamily:
          '-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif',
      }}
      className="flex flex-col min-h-[calc(100vh+4rem)]"
    >
      <NavBar bigLogo={false} />
      <Helmet>
        <title>Blog – Anysphere</title>
      </Helmet>
      <div className="w-full grid justify-items-center gap-10 pt-20 px-2">
        {data.allMdx.nodes.map(({ id, excerpt, frontmatter, slug }) => (
          <article
            key={id}
            className="max-w-3xl border px-4 py-4 rounded-lg hover:shadow-md shadow-sm bg-white"
          >
            <Link to={`/${slug}`} className="grid gap-2">
              <h2 className="text-xl font-bold">{frontmatter.title}</h2>
              <p className="text-asbrown-light">
                {frontmatter.author} — {frontmatter.date}
              </p>
              <p>{excerpt}</p>
            </Link>
          </article>
        ))}
      </div>
      <Footer />
    </main>
  );
};

export const query = graphql`
  query SITE_INDEX_QUERY {
    allMdx(
      filter: { fileAbsolutePath: { regex: "/blog/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        id
        excerpt(pruneLength: 250)
        frontmatter {
          title
          date(formatString: "MMMM DD, YYYY")
          author
        }
        slug
      }
    }
  }
`;

export default BlogPage;
